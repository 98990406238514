<template>
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ isEdit ? 'Edit Entity' : 'Create Entity' }}</p>
          <button class="delete" aria-label="close" @click="$emit('close')"></button>
        </header>
        <section class="modal-card-body">
          <form @submit.prevent="submitForm">
            <div class="field is-small">
              <label class="label is-size-7">Name*</label>
              <div class="control">
                <input class="input is-size-7" type="text" v-model="localEntity.name" required>
              </div>
            </div>
            <div class="field is-small">
              <label class="label is-size-7">Entity Code*</label>
              <div class="control">
                <input class="input is-size-7" type="text" v-model="localEntity.entity_code" required>
              </div>
            </div>
            <div class="field is-small">
              <label class="label is-size-7">TIN No*</label>
              <div class="control">
                <input class="input is-size-7" type="text" v-model="localEntity.tin_no" required>
              </div>
            </div>
            <div class="field is-small">
              <label class="label is-size-7">SST No</label>
              <div class="control">
                <input class="input is-size-7" type="text" v-model="localEntity.sst_no">
              </div>
            </div>
            <div class="field is-small">
              <label class="label is-size-7">Identity Type*</label>
              <div class="control">
                <div class="select is-size-7">
                  <select v-model="localEntity.identity_type" required>
                    <option value="BRN">BRN</option>
                    <option value="NRIC">NRIC</option>
                    <option value="PASSPORT">PASSPORT</option>
                    <option value="ARMY">ARMY</option>
                    <option value="NA">NA</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="field is-small">
              <label class="label is-size-7">Identity Number*</label>
              <div class="control">
                <input class="input is-size-7" type="text" v-model="localEntity.identity_number" required>
              </div>
            </div>
            <div class="field is-small">
              <label class="label is-size-7">Email</label>
              <div class="control">
                <input class="input is-size-7" type="email" v-model="localEntity.email">
              </div>
            </div>
            <div class="field is-small">
              <label class="label is-size-7">MSIC Code*</label>
              <div class="control">
                <input class="input is-size-7" type="text" v-model="localEntity.msic_code" required>
              </div>
            </div>
            <div class="field is-small">
              <label class="label is-size-7">Business Description*</label>
              <div class="control">
                <input class="input is-size-7" type="text" v-model="localEntity.business_description" required>
              </div>
            </div>
            <div class="field is-small">
              <label class="label is-size-7">Address 1*</label>
              <div class="control">
                <input class="input is-size-7" type="text" v-model="localEntity.address1" required>
              </div>
            </div>
            <div class="field is-small">
              <label class="label is-size-7">Address 2</label>
              <div class="control">
                <input class="input is-size-7" type="text" v-model="localEntity.address2">
              </div>
            </div>
            <div class="field is-small">
              <label class="label is-size-7">Address 3</label>
              <div class="control">
                <input class="input is-size-7" type="text" v-model="localEntity.address3">
              </div>
            </div>
            <div class="field is-small">
              <label class="label is-size-7">Zipcode</label>
              <div class="control">
                <input class="input is-size-7" type="text" v-model="localEntity.zipcode">
              </div>
            </div>
            <div class="field is-small">
              <label class="label is-size-7">City*</label>
              <div class="control">
                <input class="input is-size-7" type="text" v-model="localEntity.city" required>
              </div>
            </div>
            <div class="field is-small">
              <label class="label is-size-7">State*</label>
              <div class="control">
                <div class="select is-size-7">
                  <select v-model="localEntity.state" required>
                    <option v-for="state in states" :key="state.Code" :value="state.State">{{ state.State }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="field is-small">
              <label class="label is-size-7">Country*</label>
              <div class="control">
                <input class="input is-size-7" type="text" v-model="localEntity.country" required readonly>
              </div>
            </div>
            <div class="field is-small">
              <label class="label is-size-7">Contact Person</label>
              <div class="control">
                <input class="input is-size-7" type="text" v-model="localEntity.contact_person">
              </div>
            </div>
            <div class="field is-small">
              <label class="label is-size-7">Contact Reference</label>
              <div class="control">
                <input class="input is-size-7" type="text" v-model="localEntity.contact_reference">
              </div>
            </div>
            <div class="field is-small">
              <label class="label is-size-7">Contact Number*</label>
              <div class="control">
                <input class="input is-size-7" type="text" v-model="localEntity.contact_number" required>
              </div>
            </div>
            <div class="field is-small">
              <label class="label is-size-7">Bank Number</label>
              <div class="control">
                <input class="input is-size-7" type="text" v-model="localEntity.bank_number">
              </div>
            </div>
            <div class="field is-small">
              <label class="label is-size-7">Logo</label>
              <div class="control">
                <input class="input is-size-7" type="file" @change="onFileChange">
              </div>
            </div>
          </form>
        </section>
        <footer class="buttons modal-card-foot">
          <button class="button is-success is-small" @click="submitForm">{{ isEdit ? 'Update' : 'Create' }}</button>
          <button class="button is-small" @click="$emit('close')">Cancel</button>
          <button class="button is-danger is-small ml-2" v-if="isEdit" @click="confirmDelete">Delete</button>
        </footer>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      isEdit: Boolean,
      entity: Object,
      states: Array,
    },
    data() {
      return {
        localEntity: this.entity
          ? { ...this.entity }
          : {
              name: '',
              entity_code: '',
              tin_no: '',
              sst_no: '',
              identity_type: 'BRN',
              identity_number: '',
              email: '',
              msic_code: '',
              business_description: '',
              address1: '',
              address2: '',
              address3: '',
              zipcode: '',
              city: '',
              state: 'Selangor',
              country: 'Malaysia',
              contact_person: '',
              contact_reference: '',
              contact_number: '',
              bank_number: '',
            },
        logo: null, // Add this line
      };
    },
    methods: {
      onFileChange(event) {
        this.logo = event.target.files[0];
      },
      async submitForm() {
        const formData = new FormData();

        // Append each field from localEntity to formData
        Object.keys(this.localEntity).forEach((key) => {
          if (this.localEntity[key] !== null && this.localEntity[key] !== undefined) {
            formData.append(key, this.localEntity[key]);
          }
        });

        // Append the logo if selected
        if (this.logo) {
          formData.append('logo', this.logo);
        }

        try {
          // Emit the form data to be handled by the parent component
          await this.$emit('submit', formData);

          // Optionally emit logo-updated if you want to handle something after submission
          if (this.logo) {
            this.$emit('logo-updated', this.localEntity.entity_code);
          }
        } catch (error) {
          console.error('Error submitting form:', error);
        }
      },
      confirmDelete() {
        if (confirm('Are you sure you want to delete this entity?')) {
          this.$emit('delete', this.localEntity.id);
        }
      },
    },

  };

  </script>
  
  <style scoped>
  .ml-2 {
    margin-left: 0.5rem;
  }
  </style>
  