<template>
  <div class="card upload-csv">
    <h2 class="title is-size-6" style="display: flex; justify-content: center">Upload Invoice Template</h2>
    
    <!-- Show upload form if no response is received -->
    <form v-if="!responseReceived" @submit.prevent="uploadCSV">
      <div class="file has-name is-boxed" style="display: flex; justify-content: center;">
        <label class="file-label">
          <input class="file-input" type="file" @change="handleFileUpload" ref="file">
          <span class="file-cta">
            <span class="file-icon">
              <i class="fas fa-upload"></i>
            </span>
            <span class="file-label">Choose a file…</span>
          </span>
          <span class="file-name">{{ fileName }}</span>
        </label>
      </div>
      <div style="display: flex; justify-content: center;">
        <button class="button is-primary is-fullwidth" type="submit" :disabled="!file">Upload</button>
      </div>
    </form>

    <!-- Show response in a Bulma box once the response is received -->
    <div v-if="responseReceived" class="box">
      <h2 class="subtitle is-size-7">Processing Response</h2>
      <pre class="is-size-7">{{ responseMessage }}</pre>
      <div style="display: flex; justify-content: flex-end;">
        <button class="button is-danger" @click="resetForm">Return</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UploadCSV',
  data() {
    return {
      file: null,
      fileName: '',
      responseMessage: null, // To show the server response
      responseReceived: false, // To control whether to show response or the form
    };
  },
  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
      this.fileName = this.file.name;
    },
    async uploadCSV() {
      if (!this.file) return;

      this.$emit('loading', true);  // Notify parent loading has started

      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('created_by', localStorage.getItem('userid'));

      try {
        const response = await axios.post('/api/einvoicing_json/', formData, {
          headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`,
            'approval': 'true'
          }
        });

        // Display response message and hide upload form
        const dataString = JSON.stringify(response.data).replace(/[{}]/g, '');
        this.responseMessage = dataString;
        this.responseReceived = true;  // Set flag to hide upload form and show response
      } catch (error) {
        console.error('Error uploading file:', error);
        this.responseMessage = error.response ? JSON.stringify(error.response.data).replace(/[{}]/g, '') : 'An error occurred';
        this.responseReceived = true;  // Even on error, show the error message
      } finally {
        this.$emit('loading', false);  // Notify parent loading has stopped
      }
    },
    resetForm() {
      this.file = null;
      this.fileName = '';
      this.responseMessage = null;
      this.responseReceived = false;  // Show upload form again
    }
  }
}
</script>

<style scoped>
.upload-csv {
  padding: 20px;
  margin-bottom: 20px;
}

.file-label {
  margin-bottom: 10px;
}

.button.is-danger {
  margin-top: 10px;
}
</style>
