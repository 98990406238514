<template>
  <div class="new-document-page">
    <nav class="breadcrumb mt-5" aria-label="breadcrumbs">
      <ul>
        <li><router-link to="/dashboard">Dashboard</router-link></li>
        <li class="is-active"><router-link to="/dashboard/create-document" aria-current="true">Create Document</router-link></li>
      </ul>
    </nav>
    <div>
      <div class="column is-12 mb-2">
        <h1 class="title">Create E-Invoice Management</h1>
      </div>
      <div class="columns is-fullheight">
        <div class="column is-5 mt-5">
          <div class="left-half">
            <CreateOrEditInvoice />
          </div>
          <div class="left-half">
            <UploadCSV @loading="toggleLoading" />
          </div>
        </div>
        <div class="column is-6 is-fullheight">
          <InvoiceTable />
        </div>
      </div>
    </div>

    <!-- Fullscreen Loader Overlay -->
    <div v-if="isLoading" class="loading-overlay">
      <Vue3Lottie
        animationLink="https://assets9.lottiefiles.com/packages/lf20_kdx6cani.json"
        style="width: 150px; height: 150px;"
        :loop="true"
        :autoplay="true"
      />
    </div>
  </div>
</template>

<script>
import CreateOrEditInvoice from '@/components/NewDocument/CreateOrEditInvoice.vue';
import UploadCSV from '@/components/NewDocument/UploadCSV.vue';
import InvoiceTable from '@/components/NewDocument/InvoiceTable.vue';
import { Vue3Lottie } from 'vue3-lottie'; // Import Lottie

export default {
  name: 'NewDocumentPage',
  components: {
    CreateOrEditInvoice,
    UploadCSV,
    InvoiceTable,
    Vue3Lottie, // Register Lottie
  },
  data() {
    return {
      isLoading: false // Controls fullscreen loading
    };
  },
  methods: {
    toggleLoading(loading) {
      this.isLoading = loading; // Update loading state based on child component event
    }
  }
}
</script>

<style scoped>
.new-document-page {
  min-width: 900px;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.left-half {
  width: 60vh;
  overflow: auto;
  height: 35vh;
}

.column.is-fullheight {
  height: 80vh;
  display: flex;
  flex-direction: column;
}

.invoice-table {
  flex: 1;
  overflow-y: auto;
}

/* Fullscreen Loader Overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensures it's on top of everything */
}
</style>
